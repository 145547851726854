interface TimeDifferences {
  hoverDate: Date;
  diffInMinutes: number;
  diffInHours: number;
  diffInDays: number;
}

interface TimeDifferenceResult {
  formattedHoverDate: string;
  formattedDate: string;
  background: string;
  color: string;
}

import {
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
  format,
} from "date-fns";

export const parseUTCDate = (date: string | Date | null) => {
  if (date instanceof Date) {
    return date;
  }
  return date ? new Date(date + "Z") : null;
};

export const formatUTCDate = (value: string | Date): string => {
  let date: Date;

  if (typeof value === "string") {
    date = new Date(value + "Z");
  } else {
    date = value;
  }

  return format(date, "dd/MM/yy HH:mm:ss");
};

const getTimeDifferences = (dateString: string): TimeDifferences | null => {
  const date = parseUTCDate(dateString);
  const now = new Date();

  if (!date) {
    return null;
  }

  const hoverDate = date;
  const diffInMinutes = differenceInMinutes(now, date);
  const diffInHours = differenceInHours(now, date);
  const diffInDays = differenceInCalendarDays(now, date);

  return {
    hoverDate,
    diffInMinutes,
    diffInHours,
    diffInDays,
  };
};

export const getTimeDifferenceInfo = (
  dateString: string
): TimeDifferenceResult => {
  const timeDifferences = getTimeDifferences(dateString);

  if (!timeDifferences) {
    return {
      formattedHoverDate: "",
      formattedDate: "",
      background: "none",
      color: "none",
    };
  }

  const { hoverDate, diffInMinutes, diffInHours, diffInDays } = timeDifferences;

  const formattedHoverDate =
    hoverDate && format(hoverDate, "dd-MM-yyyy HH:mm:ss");

  let formattedDate: string;
  let background: string;
  let color: string;

  if (diffInMinutes < 60) {
    formattedDate = `Hace ${diffInMinutes} min`;
    background = "#d1fae5";
    color = "#059669";
  } else if (diffInHours < 24) {
    formattedDate = `Hace ${diffInHours} hora${diffInHours === 1 ? "" : "s"}`;
    background = "#d1fae5";
    color = "#059669";
  } else if (diffInDays <= 3) {
    formattedDate = `Hace ${diffInDays} día${diffInDays === 1 ? "" : "s"}`;
    background = "#fef3c7";
    color = "#d97706";
  } else {
    formattedDate = `Hace ${diffInDays} día${diffInDays === 1 ? "" : "s"}`;
    background = "#FFE4E6";
    color = "red";
  }

  return {
    formattedHoverDate,
    formattedDate,
    background,
    color,
  };
};
