export class WorkshopPurchasePriority {
  static readonly SCHEDULE_ID = 1;
  static readonly URGENT_ID = 2;
  static readonly PUP_ID = 3;

  static readonly SCHEDULE_LABEL = "Programado";
  static readonly URGENT_LABEL = "Emergencia";
  static readonly PUP_LABEL = "PUP";

  readonly id!: number;
  readonly name!: string;
  readonly active!: boolean;

  constructor(data: Partial<WorkshopPurchasePriority>) {
    Object.assign(this, data);
  }
}

export const priorityOptions = [
  {
    id: WorkshopPurchasePriority.SCHEDULE_ID,
    label: WorkshopPurchasePriority.SCHEDULE_LABEL,
  },
  {
    id: WorkshopPurchasePriority.URGENT_ID,
    label: WorkshopPurchasePriority.URGENT_LABEL,
  },
  {
    id: WorkshopPurchasePriority.PUP_ID,
    label: WorkshopPurchasePriority.PUP_LABEL,
  },
];
