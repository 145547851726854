import { Autocomplete, TextField } from "@mui/material";
import { GridFilterInputValueProps } from "@mui/x-data-grid";
import { SyntheticEvent } from "react";

interface OptionType {
  id: number;
  name: string;
}

interface AutocompleteFilterProps extends GridFilterInputValueProps {
  label: string;
  loading: boolean;
  options: Array<any>;
  getOptionLabel: (option: any) => string;
  getOptionValue: (option: any) => any;
}

export default function AutocompleteFilter(props: AutocompleteFilterProps) {
  const {
    item,
    applyValue,
    label,
    loading,
    options,
    getOptionLabel,
    getOptionValue,
  } = props;

  const handleFilterChange = (
    _event: SyntheticEvent<Element, Event>,
    newValue: OptionType | null
  ) => {
    applyValue({ ...item, value: newValue ? getOptionValue(newValue) : "" });
  };

  const selectedOption =
    options.find((option) => getOptionValue(option) === item.value) || null;

  return (
    <Autocomplete
      loading={loading}
      options={options}
      getOptionLabel={getOptionLabel}
      value={selectedOption}
      onChange={handleFilterChange}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="standard" fullWidth />
      )}
    />
  );
}
