import AppLink from "./Common/AppLink";
import { useAppSelector } from "../redux-store/hooks";
import { PermissionEnum } from "../enum/permission.enum";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DescriptionIcon from "@mui/icons-material/Description";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InventoryIcon from "@mui/icons-material/Inventory";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import { List } from "@mui/material";
import { RoutePath } from "./AppRoutes";

interface PropMenu {
  open: boolean;
}

export default function LayoutMenu({ open }: PropMenu) {
  const user = useAppSelector((state) => state.userReducer.user);

  return (
    <List
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        p: 1,
      }}
      component="nav"
    >
      {user.permissions.includes(PermissionEnum.WORKSHOP_PURCHASE_UPDATE) && (
        <>
          <AppLink
            to={RoutePath.INDEX}
            icon={<ShoppingCartIcon />}
            text="Compras"
            open={open}
          />
          <AppLink
            to={RoutePath.STOCK}
            icon={<InventoryIcon />}
            text="Stock"
            open={open}
          />
          <AppLink
            to={RoutePath.REPORT}
            icon={<AssignmentIcon />}
            text="Reporte"
            open={open}
          />
        </>
      )}

      {user.permissions.includes(PermissionEnum.BUDGET_WORKSHOP_INDEX) && (
        <>
          <AppLink
            to={RoutePath.BUDGET}
            icon={<DescriptionIcon />}
            text="Presupuestos"
            open={open}
          />
          <AppLink
            to={RoutePath.CALENDAR}
            icon={<CalendarMonthIcon />}
            text="Calendario"
            open={open}
          />
        </>
      )}

      {user.permissions.includes(PermissionEnum.USER_WORK_INDEX) && (
        <AppLink
          to={RoutePath.MECHANICS}
          icon={<HomeRepairServiceIcon />}
          text="Trabajos en curso"
          open={open}
        />
      )}
    </List>
  );
}
