import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "../.././index.css";
import { WorkshopPurchaseItemState } from "../../entities/workshop-purchase-item-state.entity";
import { useMemo } from "react";
import { Tooltip } from "@mui/material";

interface Segment {
  name: string;
  progress: number;
  total: number;
  colorClass: string;
}

interface LinearProgressWithLabelProps {
  value: number;
  segments: Segment[];
}

function LinearProgressWithLabel({
  value,
  segments,
}: LinearProgressWithLabelProps) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <Box className="progress-bar">
          {segments
            .filter((s) => s.progress > 0)
            .map((segment, index) => {
              const percentage = (segment.progress / segment.total) * 100;
              const tooltip = `${segment.name}: ${segment.progress} de ${
                segment.total
              } ${segment.progress == 1 ? "ítem" : "ítems"}`;

              return (
                <Tooltip key={index} title={tooltip}>
                  <Box
                    className={`progress-bar__segment ${segment.colorClass}`}
                    style={{
                      width: `${
                        percentage === 100 ? percentage : percentage - 0.2
                      }%`,
                    }}
                  />
                </Tooltip>
              );
            })}
        </Box>
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

interface ComponentProps {
  states: string[];
}

export default function StateProgressBar({ states }: ComponentProps) {
  const { totalProgress, segments } = useMemo(() => {
    if (!states || states.length === 0) {
      return {
        totalProgress: 0,
        segments: [] as Segment[],
      };
    }

    const totalItems = states.length;
    const finishedItems = states.filter(
      (state) =>
        state === WorkshopPurchaseItemState.VERIFICATION_PENDING_LABEL ||
        state === WorkshopPurchaseItemState.FINISHED_LABEL ||
        state === WorkshopPurchaseItemState.IN_STOCK_LABEL
    ).length;
    const notQuotedItems = states.filter(
      (state) =>
        state === WorkshopPurchaseItemState.REJECTED_LABEL ||
        state === WorkshopPurchaseItemState.MISSING_FROM_FACTORY_LABEL ||
        state === WorkshopPurchaseItemState.QUOTE_LABEL
    ).length;
    const inProgressItems = totalItems - finishedItems - notQuotedItems;

    const segments = [
      {
        name: "Hecho",
        progress: finishedItems,
        total: totalItems,
        colorClass: "progress-bar__segment--finished",
      },
      {
        name: "En curso",
        progress: inProgressItems,
        total: totalItems,
        colorClass: "progress-bar__segment--in-progress",
      },
      {
        name: "Por hacer",
        progress: notQuotedItems,
        total: totalItems,
        colorClass: "progress-bar__segment--not-quoted",
      },
    ];

    return {
      totalProgress: (finishedItems / totalItems) * 100,
      segments,
    };
  }, [states]);

  return <LinearProgressWithLabel value={totalProgress} segments={segments} />;
}
