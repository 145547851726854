import { ReactNode, useState } from "react";
import { BudgetContext, BudgetProps } from "./BudgetContext";
import { BudgetService } from "../../../../services/BudgetService";
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { Budget } from "../../../../entities/budget.entity";

interface BudgetProviderProps {
  children: ReactNode;
}

export const BudgetProvider = ({ children }: BudgetProviderProps) => {
  const queryClient = useQueryClient();
  const budgetService = new BudgetService();

  const [open, setOpen] = useState(false);
  const [selectedBudget, setSelectedBudget] = useState<any>(null);
  const [filters, setFilters] = useState<any>({});
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);

  const { data: activeBudgets, isLoading: loadingAllBudgets } = useQuery({
    queryKey: ["activeBudgets"],
    queryFn: () => budgetService.findAllBudgets(),
  });

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: ["budgets", filters],
      queryFn: async ({ pageParam = 1 }) => {
        const response = await budgetService.find({ page: pageParam, filters });
        setTotalCount(response.totalCount);
        setPageSize(response.pageSize);
        return response;
      },
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.data.length > 0 ? nextPage : undefined;
      },
      initialPageParam: 1,
    });

  const pagedBudgets = data?.pages.flatMap((page) => page.data) || [];

  const replaceBudgetInQueryData = (
    budgetId: number,
    updatedBudget: Budget
  ) => {
    queryClient.setQueryData(["budgets", filters], (oldData: any) => {
      if (!oldData) return oldData;

      return {
        ...oldData,
        pages: oldData.pages.map((page: any) => ({
          ...page,
          data: page.data.map((budget: Budget) =>
            budget.id === budgetId ? updatedBudget : budget
          ),
        })),
      };
    });

    queryClient.setQueryData(
      ["activeBudgets"],
      (oldData: Budget[] | undefined) => {
        if (!oldData) return oldData;

        return oldData.map((budget) =>
          budget.id === budgetId ? updatedBudget : budget
        );
      }
    );
  };

  const updateBudget = async (
    budgetId: number,
    updatedBudget: Partial<Budget>
  ) => {
    try {
      const updated = await budgetService.update(budgetId, updatedBudget);

      replaceBudgetInQueryData(budgetId, updated);

      //TODO: Check if this is necessary
      if (selectedBudget?.id === budgetId) {
        setSelectedBudget(updated);
      }
    } catch (error) {
      console.error("Error updating budget:", error);
    }
  };

  const updateMechanics = async (budgetId: number, userIds: number[]) => {
    try {
      const updatedBudget = await budgetService.updateMechanics(
        budgetId,
        userIds
      );

      replaceBudgetInQueryData(budgetId, updatedBudget);

      if (selectedBudget?.id === budgetId) {
        setSelectedBudget(updatedBudget);
      }
    } catch (error) {
      console.error("Error updating mechanics:", error);
    }
  };

  const budgetContextValue: BudgetProps = {
    open,
    setOpen,
    activeBudgets: activeBudgets || [],
    pagedBudgets,
    selectedBudget,
    setSelectedBudget,
    loading: isLoading || loadingAllBudgets,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    updateBudget,
    updateMechanics,
    setFilters,
    totalCount,
    pageSize,
  };
  return (
    <BudgetContext.Provider value={budgetContextValue}>
      {children}
    </BudgetContext.Provider>
  );
};
