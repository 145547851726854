import { PurchaseContext } from "./PurchaseContext";
import { useEffect, useState } from "react";
import { PurchaseProps } from "./PurchaseContext";
import { WorkshopPurchase } from "../../../../entities/workshop-purchase.entity";
import { WorkshopPurchaseService } from "../../../../services/WorkshopPurchaseService";
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

interface PurchaseProviderProps {
  children: JSX.Element | JSX.Element[];
}

interface PurchasesResponse {
  data: WorkshopPurchase[];
  totalCount: number;
  pageSize: number;
}

export const PurchaseProvider = ({ children }: PurchaseProviderProps) => {
  const queryClient = useQueryClient();
  const purchaseService = new WorkshopPurchaseService();

  const [open, setOpen] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState<
    undefined | WorkshopPurchase
  >(undefined);
  const [filters, setFilters] = useState<any>({});
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);

  const {
    data: initialKanbanPurchases,
    isLoading: loadingInitialKanbanPurchases,
  } = useQuery({
    queryKey: ["kanbanPurchases", "initial"],
    queryFn: () => purchaseService.findAllPurchases(30),
    enabled: !!filters,
  });

  const { data: fullKanbanPurchases } = useQuery({
    queryKey: ["kanbanPurchases", "full"],
    queryFn: () => purchaseService.findAllPurchases(),
    enabled: !!initialKanbanPurchases,
    staleTime: Infinity,
  });

  const kanbanPurchases = fullKanbanPurchases || initialKanbanPurchases || [];

  const {
    data: queryData,
    isLoading: loadingPurchases,
    isPlaceholderData,
  } = useQuery<PurchasesResponse, Error>({
    queryKey: ["purchases", filters, page, pageSize],
    queryFn: async () => {
      const response = await purchaseService.find({
        page,
        pageSize,
        filters,
      });
      return response;
    },
    placeholderData: keepPreviousData,
  });

  const data = queryData ?? { data: [], totalCount: 0, pageSize: 20 };

  const { data: purchases, totalCount } = data;

  useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: ["purchases", filters, page + 1, pageSize],
      queryFn: () =>
        purchaseService.find({ page: page + 1, pageSize, filters }),
    });
  }, [page, filters, pageSize, queryClient]);

  const updatePurchase = async (
    purchaseId: number,
    updatedPurchase: Partial<WorkshopPurchase>
  ) => {
    try {
      const updated = await purchaseService.update(purchaseId, updatedPurchase);

      queryClient.setQueryData(
        ["purchases", filters, page, pageSize],
        (oldData: WorkshopPurchase[] | undefined) => {
          if (!oldData) return oldData;

          return oldData.map((purchase: WorkshopPurchase) =>
            purchase.id === purchaseId ? updated : purchase
          );
        }
      );

      queryClient.setQueryData(
        ["kanbanPurchases"],
        (oldData: WorkshopPurchase[] | undefined) => {
          if (!oldData) return oldData;

          return oldData.map((purchase) =>
            purchase.id === purchaseId ? updated : purchase
          );
        }
      );

      if (selectedPurchase?.id === purchaseId) {
        setSelectedPurchase(updated);
      }
    } catch (error) {
      console.error("Error updating purchase:", error);
    }
  };

  const purchaseContextValue: PurchaseProps = {
    open,
    setOpen,
    kanbanPurchases: kanbanPurchases || [],
    selectedPurchase,
    setSelectedPurchase,
    purchases,
    setPurchases: () => {},
    loading: loadingInitialKanbanPurchases || loadingPurchases,
    setLoading: () => {},
    totalCount,
    setFilters,
    pageSize,
    setPageSize,
    page,
    setPage,
    updatePurchase,
    isPlaceholderData,
    filters,
  };

  return (
    <PurchaseContext.Provider value={purchaseContextValue}>
      {children}
    </PurchaseContext.Provider>
  );
};
