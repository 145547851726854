export class Workshop {
  readonly id!: number;
  readonly name!: string;
  readonly observation!: string;

  constructor(data: Partial<Workshop>) {
    Object.assign(this, data);
  }
}

export const inWorkshopOptions = [
  { id: 1, label: "Sí" },
  { id: 0, label: "No" },
];
