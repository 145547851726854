import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridRowClassNameParams,
} from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";
import ModalDetail from "../../Purchase/ModalDetail";
import { WorkshopPurchase } from "../../../entities/workshop-purchase.entity";

import "./Index.css";
import { Box, Typography } from "@mui/material";
import PriorityIcon from "../../Purchase/PriorityIcon";
import { Car } from "../../../entities/car.entity";
import { useState } from "react";
import { useContext } from "react";
import { PurchaseContext } from "./context/PurchaseContext";
import {
  stateOptions,
  WorkshopPurchaseState,
} from "../../../entities/workshop-purchase-state.entity";
import { useDarkMode } from "../../ThemeContext";
import { format, parse } from "date-fns";
import GenericSelectFilter from "./GenericSelectFilter";
import { priorityOptions } from "../../../entities/WorkshopPurchasePriority.entity";
import { inWorkshopOptions } from "../../../entities/workshop.entity";

export default function Backlog() {
  const { open, setOpen } = useContext(PurchaseContext);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const {
    selectedPurchase,
    setSelectedPurchase,
    purchases,
    updatePurchase,
    totalCount,
    pageSize,
    setPageSize,
    page,
    setPage,
    setFilters,
    isPlaceholderData,
  } = useContext(PurchaseContext);

  const { isDarkMode } = useDarkMode();

  const handleFilterChange = (model: GridFilterModel) => {
    setFilterModel(model);

    const newFilters = model.items.reduce(
      (acc: { [key: string]: any }, filter) => {
        let value = filter.value;

        if (filter.field === "created_at") {
          const possibleFormats = [
            "dd/MM/yyyy",
            "MM/dd/yyyy",
            "yyyy-MM-dd",
            "dd-MM-yyyy",
            "MM-dd-yyyy",
            "MMM d, yyyy",
            "MMMM d, yyyy",
          ];

          let parsedDate;
          for (const formatString of possibleFormats) {
            parsedDate = parse(value, formatString, new Date());
            if (!isNaN(parsedDate.getTime())) {
              break;
            }
          }

          if (parsedDate && !isNaN(parsedDate.getTime())) {
            value = format(parsedDate, "yyyy-MM-dd");
          } else {
            console.warn("Formato de fecha inválido:", value);
            value = null;
          }
        }

        if (value !== undefined && value !== null && value !== "") {
          acc[filter.field] = value;
        }
        return acc;
      },
      {}
    );

    setFilters(newFilters);
    setPage(1);
  };

  const handlePageChange = (params: GridPaginationModel) => {
    setPage(params.page + 1);
    setPageSize(params.pageSize);
  };

  const handleOpen = (purchase: any) => {
    setSelectedPurchase(purchase);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const getRowId = (purchase: WorkshopPurchase) => purchase.id;

  const getRowClassName = (
    params: GridRowClassNameParams<WorkshopPurchase>
  ) => {
    const isAdditional = params.row.is_additional;

    switch (true) {
      case isAdditional && isDarkMode:
        return "additionalRowDark";
      case isAdditional && !isDarkMode:
        return "additionalRow";
      default:
        return "";
    }
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 0.6, filterable: true },
    {
      field: "workshop_purchase_priority_id",
      headerName: "Prioridad",
      flex: 1,
      filterable: true,
      filterOperators: [
        {
          label: "Es",
          value: "equals",
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return ({ value }) => value === filterItem.value;
          },
          InputComponent: (props) => (
            <GenericSelectFilter
              {...props}
              label="Prioridad"
              options={priorityOptions}
            />
          ),
        },
      ],
      renderCell: (params) => (
        <>
          {params.row.workshopPurchasePriority?.name || ""}
          {params.row.workshopPurchasePriority?.id !== undefined && (
            <PriorityIcon id={params.row.workshopPurchasePriority.id} />
          )}
        </>
      ),
      valueGetter: (params) => {
        return params.row.workshopPurchasePriority?.id;
      },
    },
    {
      field: "workshop_id",
      headerName: "Taller",
      flex: 1,
      filterable: true,
      valueGetter: (params) => {
        return `${params.row.budget.workshop.name}`;
      },
    },
    {
      field: "budget_id",
      headerName: "N° de presupuesto",
      flex: 1,
      filterable: true,
      valueGetter: (params) => {
        return `${params.row.budget.id}`;
      },
    },
    {
      field: "company_id",
      headerName: "Cliente",
      flex: 1,
      filterable: true,
      valueGetter: (params) => {
        return `${params.row.budget.company.name}`;
      },
    },
    {
      field: "identification",
      headerName: "Unidad",
      flex: 1,
      valueGetter: (params) => {
        return Car.getSummary(params.row.budget.car);
      },
    },
    {
      field: "color",
      headerName: "Color",
      flex: 0.5,
      filterable: true,
      valueGetter: (params) => {
        return `${params.row.budget.car.color}`;
      },
    },
    {
      field: "usage",
      headerName: "KM",
      flex: 0.5,
      filterable: true,
      valueGetter: (params) => {
        return `${params.row.budget.car.usage}`;
      },
    },
    {
      field: "engine_number",
      headerName: "N° Motor",
      flex: 1,
      filterable: true,
      valueGetter: (params) => {
        return `${params.row.budget.car.engine_number}`;
      },
    },
    {
      field: "chassis_number",
      headerName: "N° Chasis",
      flex: 1,
      filterable: true,
      valueGetter: (params) => {
        return `${params.row.budget.car.chassis_number}`;
      },
    },
    {
      field: "created_at",
      headerName: "Fecha de solicitud",
      flex: 1,
      filterable: true,
    },
    {
      field: "in_workshop",
      headerName: "En taller",
      flex: 0.6,
      filterable: true,
      renderCell: (params) => (
        <>{`${params.row.budget.in_workshop ? "Sí" : "No"}`}</>
      ),
      valueGetter: (params) => params.row.budget.in_workshop,
      filterOperators: [
        {
          label: "Es",
          value: "equals",
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return ({ value }) => value === filterItem.value;
          },
          InputComponent: (props) => (
            <GenericSelectFilter
              {...props}
              label="en taller"
              options={inWorkshopOptions}
            />
          ),
        },
      ],
    },
    {
      field: "workshop_purchase_state_id",
      headerName: "Estado",
      flex: 1,
      filterable: true,
      filterOperators: [
        {
          label: "Es",
          value: "equals",
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return ({ value }) => {
              return value === filterItem.value;
            };
          },
          InputComponent: (props) => (
            <GenericSelectFilter
              {...props}
              label="Estado"
              options={stateOptions}
            />
          ),
        },
      ],
      valueGetter: (params) => {
        const state = params.row.workshopPurchaseState.name;
        let color = "";
        let background = "";

        switch (state) {
          case WorkshopPurchaseState.QUOTE_LABEL:
          case WorkshopPurchaseState.BUY_LABEL:
            color = "#d97706";
            background = "#fef3c7";
            break;
          case WorkshopPurchaseState.QUOTED_LABEL:
            color = "#2563eb";
            background = "#dbeafe";
            break;
          case WorkshopPurchaseState.FINISHED_LABEL:
          case WorkshopPurchaseState.DELIVERED_LABEL:
            color = "#059669";
            background = "#d1fae5";
            break;
          case WorkshopPurchaseState.SPEC_LABEL:
          case WorkshopPurchaseState.CANCELLED_LABEL:
          case WorkshopPurchaseState.EXPIRED_LABEL:
          case WorkshopPurchaseState.REJECTED_LABEL:
            color = "#6b7280";
            background = "#d1d5db";
            break;
          default:
            color = "";
        }
        return { state, color, background };
      },
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.value.color,
            background: params.value.background,
            borderRadius: "5px",
            padding: "2px 3px 2px 3px",
          }}
        >
          {params.value.state}
        </Typography>
      ),
    },
  ];

  return (
    <>
      <Box>
        <h2>Compras</h2>
        <DataGrid
          sx={{ minHeight: "50vh" }}
          loading={isPlaceholderData}
          slotProps={{ row: { style: { cursor: "pointer" } } }}
          rows={purchases}
          columns={columns}
          onRowClick={(params) => handleOpen(params.row)}
          getRowId={getRowId}
          getRowClassName={getRowClassName}
          pagination
          paginationMode="server"
          rowCount={totalCount}
          paginationModel={{ page: page - 1, pageSize }}
          onPaginationModelChange={handlePageChange}
          filterMode="server"
          onFilterModelChange={handleFilterChange}
          filterModel={filterModel}
        />
        {selectedPurchase && (
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box>
              <ModalDetail
                carSummary={Car.getSummary(selectedPurchase.budget.car)}
                purchase={selectedPurchase}
                handleClose={handleClose}
                onPurchaseChange={async (updatedPurchase: WorkshopPurchase) => {
                  await updatePurchase(updatedPurchase.id, updatedPurchase);
                  setSelectedPurchase(updatedPurchase);
                }}
              />
            </Box>
          </Modal>
        )}
      </Box>
    </>
  );
}
