import { WorkshopPurchaseState } from "./workshop-purchase-state.entity";
import { Budget } from "./budget.entity";
import { Workshop } from "./workshop.entity";
import { User } from "./user.entity";
import { WorkshopPurchaseItem } from "./workshop-purchase-item.entity";
import { WorkshopPurchasePriority } from "./WorkshopPurchasePriority.entity";

export class WorkshopPurchase {
  readonly id!: number;
  readonly workshop_purchase_state_id!: number;
  readonly parent_id!: number;
  readonly responsible_id!: number;
  readonly workshopPurchaseState!: WorkshopPurchaseState;
  readonly workshopPurchaseItems!: WorkshopPurchaseItem[];
  readonly responsible!: User;
  readonly budget!: Budget;
  readonly workshop!: Workshop;
  readonly createdBy!: User;
  readonly created_at!: Date;
  readonly end_date?: string | Date;
  readonly expiration_date?: string | Date;
  readonly rejected_date?: string | Date;
  readonly workshopPurchasePriority!: WorkshopPurchasePriority;
  readonly creation_date!: Date;
  readonly workshop_purchase_priority_id!: number | null;
  readonly budget_id!: number;
  readonly budget_company_name!: string;
  readonly car_identification!: string;
  readonly car_color!: string;
  readonly car_usage!: number | null;
  readonly car_engine_number!: string;
  readonly car_chassis_number!: string;
  readonly is_car_in_workshop!: boolean;
  readonly is_additional!: boolean;
  readonly workshop_purchase_state_name!: string;
  readonly last_state_change_date!: string;

  constructor(data: Partial<WorkshopPurchase>) {
    Object.assign(this, data);
  }
}
