import { UserWorkBudget } from "../entities/user-work-budget.entity";
import ApiService from "./ApiService";

type StopTimerResponse = {
  total_time: string;
};

type PendingJobResponse = {
  budget_id: number;
};

export class UserWorkService {
  async getAssignedBudgets(): Promise<UserWorkBudget[]> {
    const response = await ApiService.get<{ data: UserWorkBudget[] }>(
      "/api_v2/user-work/assigned"
    );
    return response.data.data;
  }

  async start(budgetId: number): Promise<any> {
    try {
      const response = await ApiService.post(
        `/api_v2/user-work/start?budget_id=${budgetId}`
      );

      return response.data.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(
          error.response.data.error || "Error al iniciar el temporizador"
        );
      } else if (error.request) {
        throw new Error("No se recibió respuesta del servidor");
      } else {
        throw new Error("Error al iniciar el temporizador");
      }
    }
  }

  async stop(
    budgetId: number,
    taskID: number,
    comment: string
  ): Promise<StopTimerResponse> {
    try {
      const response = await ApiService.post(
        `/api_v2/user-work/stop?budget_id=${budgetId}`,
        {
          task_id: taskID,
          comments: comment,
        }
      );
      return response.data.data as StopTimerResponse;
    } catch (error: any) {
      if (error.response) {
        throw new Error(
          error.response.data.error || "Error al detener el temporizador"
        );
      } else if (error.request) {
        throw new Error("No se recibió respuesta del servidor");
      } else {
        throw new Error("Error al detener el temporizador");
      }
    }
  }

  async PendingJob(): Promise<PendingJobResponse> {
    try {
      const response = await ApiService.get(`/api_v2/user-work/pending`);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async updateEndDate(
    budgetID: number,
    endDate: string,
    taskID: number,
    comments: string
  ): Promise<void> {
    try {
      const response = await ApiService.post(
        `/api_v2/user-work/update?budget_id=${budgetID}`,
        {
          end_date: endDate,
          task_id: taskID,
          comments: comments,
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error assigning mechanics: ", error);
      throw error;
    }
  }
}
