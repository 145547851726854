import { SyntheticEvent, useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { FormattedNumber } from "react-intl";
import { WorkshopPurchaseService } from "../../services/WorkshopPurchaseService";
import Loading from "../Common/Loading";
import CommentSection from "./CommentSection";
import { WorkshopService } from "../../services/WorkshopService";
import { WorkshopPurchase } from "../../entities/workshop-purchase.entity";
import { WorkshopPurchasePriority } from "../../entities/WorkshopPurchasePriority.entity";
import { formatUTCDate } from "../../utils/dateUtils";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface PurchaseHistoryRow {
  id: number;
  action: string;
  field: string | null;
  formatted_new_value: string | null;
  formatted_old_value: string | null;
  model: string;
  model_id: string;
  new_value: string;
  old_value: string;
  source: string;
  stamp: string;
  user_fullname: string;
  user_id: string;
}

interface ComponentProps {
  purchase: WorkshopPurchase;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function History({ purchase }: ComponentProps) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [rows, setRows] = useState<PurchaseHistoryRow[]>([]);
  const [loading, setLoading] = useState(true);

  const purchaseService = new WorkshopPurchaseService();
  const workshopService = new WorkshopService();

  const purchaseItemIds = purchase.workshopPurchaseItems.map((item) => item.id);

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (window.location.hash.includes("#comment-")) {
      setSelectedTab(1);
    }

    const fetchAndProcessHistory = async () => {
      setLoading(true);

      try {
        const res = await purchaseService.history(
          [purchase.id],
          purchaseItemIds
        );

        if (!res || res.length === 0) {
          console.warn("No se recibieron datos del historial.");
          setRows([]);
          return;
        }

        let rowsWithCreation = res.map((row) => ({ ...row }));

        rowsWithCreation = rowsWithCreation.map((row) => {
          if (
            row.action === "CREATE" &&
            row.model === "backend\\models\\WorkshopPurchase"
          ) {
            return {
              ...row,
              field: "Creación",
              formatted_new_value: "Compra creada",
              model: "Solicitud de compra",
            };
          }
          return row;
        });

        const responsibleChanges = rowsWithCreation.filter(
          (row) =>
            row.model === "backend\\models\\WorkshopPurchase" &&
            row.action === "CHANGE" &&
            row.field === "responsible_id" &&
            (row.formatted_new_value || row.formatted_old_value)
        );

        const responsibleNames = await Promise.all(
          responsibleChanges.map(async (row) => {
            const workshopId = purchase.workshop.id;
            const users = await workshopService.users(workshopId);

            const results: { newValueName: string; oldValueName: string } = {
              newValueName: "Desconocido",
              oldValueName: row.formatted_old_value
                ? "Desconocido"
                : "Sin responsable previo",
            };

            if (row.formatted_new_value) {
              const newUserId = parseInt(row.formatted_new_value ?? "", 10);
              if (!isNaN(newUserId)) {
                try {
                  const selectedResponsible = users.find(
                    (r: any) => r.id === newUserId
                  );
                  if (selectedResponsible) {
                    results.newValueName = selectedResponsible.fullname;
                  }
                } catch (error) {
                  console.error(
                    `Error al obtener el usuario con ID ${newUserId}:`,
                    error
                  );
                }
              }
            }

            if (row.formatted_old_value) {
              const oldUserId = parseInt(row.formatted_old_value ?? "", 10);
              if (!isNaN(oldUserId)) {
                try {
                  const selectedResponsible = users.find(
                    (r: any) => r.id === oldUserId
                  );
                  if (selectedResponsible) {
                    results.oldValueName = selectedResponsible.fullname;
                  }
                } catch (error) {
                  console.error(
                    `Error al obtener el usuario con ID ${oldUserId}:`,
                    error
                  );
                }
              }
            }

            return { id: row.id, ...results };
          })
        );

        rowsWithCreation = rowsWithCreation.map((row) => {
          if (row.model === "backend\\models\\WorkshopPurchase") {
            switch (row.field) {
              case "responsible_id": {
                if (row.action === "CHANGE") {
                  const responsibleInfo = responsibleNames.find(
                    (user) => user.id === row.id
                  );

                  if (responsibleInfo) {
                    row.formatted_new_value = responsibleInfo.newValueName;
                    row.formatted_old_value = responsibleInfo.oldValueName;
                  }
                }
                break;
              }

              case "workshop_purchase_priority_id": {
                const newPriorityId = parseInt(
                  row.formatted_new_value ?? "",
                  10
                );
                const oldPriorityId = parseInt(
                  row.formatted_old_value ?? "",
                  10
                );

                row.formatted_new_value = getPriorityLabel(newPriorityId);
                row.formatted_old_value = oldPriorityId
                  ? getPriorityLabel(oldPriorityId)
                  : "Sin prioridad previa";
                break;
              }
              default:
                break;
            }
          }
          return row;
        });
        rowsWithCreation = rowsWithCreation.map((row) => {
          if (
            row.model === "backend\\models\\WorkshopPurchaseItem" &&
            row.action === "CREATE"
          ) {
            const relatedItem = purchase.workshopPurchaseItems.find(
              (item) => item.id === parseInt(row.model_id, 10)
            );

            return {
              ...row,
              field: "Creación de Item",
              formatted_new_value: `${
                relatedItem?.budgetItem.repairSubitem.name || "Desconocido"
              }`,
              model: "Item de compra",
            };
          }
          return row;
        });

        setRows(rowsWithCreation);
      } catch (error) {
        console.error("Error al obtener o procesar el historial:", error);
        setRows([]);
      } finally {
        setLoading(false);
      }
    };

    fetchAndProcessHistory();
  }, [purchase]);

  const getPriorityLabel = (priorityId: number): string => {
    switch (priorityId) {
      case WorkshopPurchasePriority.SCHEDULE_ID:
        return WorkshopPurchasePriority.SCHEDULE_LABEL;
      case WorkshopPurchasePriority.URGENT_ID:
        return WorkshopPurchasePriority.URGENT_LABEL;
      case WorkshopPurchasePriority.PUP_ID:
        return WorkshopPurchasePriority.PUP_LABEL;
      default:
        return "Prioridad desconocida";
    }
  };

  const columns: GridColDef[] = [
    { field: "user_fullname", headerName: "Usuario", flex: 0.6 },
    {
      field: "field",
      headerName: "Cambio",
      flex: 0.8,
      renderCell: (params) => {
        switch (params.value) {
          case "price":
            return "Precio";
          case "quantity":
            return "Cantidad";
          case "workshop_purchase_state_id":
          case "workshop_purchase_item_state_id":
            return "Estado";
          case "responsible_id":
            return "Responsable";
          case "description":
            return "Descripción";
          case "workshop_purchase_priority_id":
            return "Prioridad";
          case "workshop_id":
            return "Taller asignado";
          case "repair_id":
            return "Item";
          case "part_id":
            return "Marca - Nº de pieza";
          default:
            return params.value;
        }
      },
    },
    {
      field: "formatted_old_value",
      headerName: "Valor Anterior",
      flex: 1,
      renderCell: (params) => {
        if (params.row.field == "price" && params.value > 0) {
          return (
            <FormattedNumber
              value={params.value}
              style="currency"
              currency="ARS"
            />
          );
        }

        return params.value;
      },
    },
    {
      field: "formatted_new_value",
      headerName: "Valor Nuevo",
      flex: 1,
      renderCell: (params) => {
        if (params.row.field == "price" && params.value > 0) {
          return (
            <FormattedNumber
              value={params.value}
              style="currency"
              currency="ARS"
            />
          );
        }

        return params.value;
      },
    },
    {
      field: "model",
      headerName: "Item Modificado",
      flex: 1,
      renderCell: (params) => {
        switch (params.row.model) {
          case "backend\\models\\WorkshopPurchaseItem":
            const relatedItem = purchase.workshopPurchaseItems.find(
              (item) => item.id.toString() === params.row.model_id
            );

            if (relatedItem && relatedItem.budgetItem.repairSubitem) {
              return relatedItem.budgetItem.repairSubitem.name;
            }
            return "Item desconocido";

          case "backend\\models\\WorkshopPurchase":
            return "Cambio en solicitud de compra";

          default:
            return params.value || "Item desconocido";
        }
      },
    },
    {
      field: "stamp",
      headerName: "Fecha de Ejecución",
      flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return "Fecha no disponible";
        }

        try {
          return formatUTCDate(params.value);
        } catch (error) {
          console.error("Error parsing date:", error);
          return "Fecha inválida";
        }
      },
    },
  ];

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          minHeight: "300px",
        }}
      >
        <Loading component="historial de cambios" />
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%", minHeight: "250px" }}>
      <Tabs value={selectedTab} onChange={handleChange}>
        <Tab label="Historial de cambios" {...a11yProps(0)} />
        <Tab label="Comentarios" {...a11yProps(1)} />
      </Tabs>
      <CustomTabPanel value={selectedTab} index={0}>
        {!rows.length ? (
          <p>Sin cambios</p>
        ) : (
          <DataGrid
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            rows={rows}
            columns={columns}
          />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={1}>
        <CommentSection purchaseId={purchase.id} />
      </CustomTabPanel>
    </Box>
  );
}
