import { WorkshopPurchaseItem } from "../entities/workshop-purchase-item.entity";
import { BaseApplicationService } from "../interfaces/base-applicaton-service.interface";
import { FieldErrors } from "../types/FieldError";
import downloadFile from "../utils/downloadFile";
import ApiService, { fetchPaginatedData } from "./ApiService";

type Params = {};

export class WorkshopPurchaseItemService
  implements BaseApplicationService<WorkshopPurchaseItem, Params>
{
  findOneById(_id: number): Promise<WorkshopPurchaseItem> {
    throw new Error("Method not implemented.");
  }
  findOne(_params: Params): Promise<WorkshopPurchaseItem> {
    throw new Error("Method not implemented.");
  }
  find(_params: Params): Promise<WorkshopPurchaseItem[]> {
    throw new Error("Method not implemented.");
  }
  async update(
    itemId: number,
    item: Partial<WorkshopPurchaseItem>
  ): Promise<WorkshopPurchaseItem | FieldErrors> {
    const response = await ApiService.post(
      `/api_v2/workshop-purchase-item/update?id=${itemId}`,
      item
    );

    if (response && !response.data.success) {
      return {
        errors: response.data.data,
      };
    }

    return new WorkshopPurchaseItem(response?.data.data ?? item);
  }

  getTotal(item: WorkshopPurchaseItem): number {
    return item.price * item.quantity;
  }

  async getHistoricalReportData(
    page = 1,
    pageSize = 20,
    filters?: any
  ): Promise<{ data: any[]; totalCount: number }> {
    const result = await fetchPaginatedData<any>(
      "/api_v2/workshop-purchase-item/report",
      page,
      pageSize,
      filters
    );
    return result as { data: any[]; totalCount: number };
  }

  async downloadReport() {
    try {
      const response = await ApiService.get(
        "/api_v2/workshop-purchase-item/report",
        {
          params: { download: 1 },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(response.data);
      downloadFile(url, "Reporte_de_cambio_de_estados_de_items_de_compra.xlsx");
    } catch (error) {
      console.error("Error al descargar el archivo:", error);
      throw error;
    }
  }

  async getLatestQuote(id: number) {
    const response = await ApiService.get(
      `/api_v2/workshop-purchase-item/latest-quote?id=${id}`
    );

    return response?.data.data;
  }
}
